.toast-wrapper {
  width: 402px;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.33;

  &.Toastify__toast-container--bottom-center {
    left: calc(50% - 42px);
    bottom: 65px !important;
  }

  * {
    outline: unset !important;
    font-family: Roboto, serif !important;
  }

  .Toastify__toast {
    min-height: 54px;
    border-radius: 10px;

    .toastify-close-icon {
      display: flex;
      align-items: center;
      justify-content: center;

      &:before {
        font-size: 17px;
        padding-right: 10px;
      }
    }
  }

  .Toastify__toast--success {
    background-color: #00c391;

    .Toastify__progress-bar {
      background-color: rgba(95, 52, 163, 0.1);
    }

    .toastify-close-icon {
      &:before {
        color: #143d66 !important;
      }
    }
  }

  .Toastify__toast--error {
    background-color: #fa5454;

    .Toastify__progress-bar {
      background-color: rgba(230, 83, 95, 0.1);
    }

    .toastify-close-icon {
      &:before {
        color: white !important;
      }
    }
  }

  .Toastify__toast-body {
    display: flex;
    justify-content: center;
  }
}
