@import 'src/utils';

.fuel-create-or-edit-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .fuel-create-or-edit-wrap {
    display: flex;
    width: 656px;
    overflow: scroll;
    max-height: 95vh;
    min-height: 368px;
    position: relative;
    border-radius: 10px;
    flex-direction: column;
    background-color: white;
    padding: 14px 169px 40px;

    &.is-loading {
      position: relative;
      justify-content: flex-start;
      padding-top: calc(368px / 2.1);
    }

    .middle-items {
      display: flex;
      margin-top: 40px;
      margin-bottom: 40px;
      flex-direction: column;

      .pointsPerLiter {
        width: 80% !important;
      }
    }

    .body-title {
      padding-top: 10px;
      color: #4a4a4a;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }

    .icon-fechar:before {
      right: 25px;
      color: #143d66;
      font-size: 32px;
      cursor: pointer;
      position: absolute;
    }
  }
}
