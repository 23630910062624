@import 'src/utils';
@import '../src/media-query';
@import './styles/constants/fonts.scss';
@import './styles/constants/colors.scss';

* {
    outline: unset !important;
    font-family: Roboto, serif !important;
}

.Toaster__manager-bottom {
    bottom: 8px !important;
}

.App {
    display: flex;
    height: 100%;
    overflow: auto;
    position: relative;
    flex-direction: column;
}

.App-routes-wrapper {
    display: flex;
    height: 100%;
    margin-top: 0;
    background: white;
    flex-direction: column;
    .App-routes-wrap {
        display: flex;
        flex: 1;
        flex-direction: column;
    }
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: white;
    // min-height: 100vh;
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.justify-content-center {
    justify-content: center;
}

.align-content-center {
    align-content: center;
}

.form {
    .MuiFormControl-root {
        margin-top: 25px;
    }
}

a {
    text-decoration: none;
}

a:hover {
    text-decoration: none;
}

.disable-copy {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.step-container {
    margin-top: -28px;
    display: flex;
    flex-direction: column;
    align-self: center;
    align-items: center;
    justify-content: center;
    @include responsive(s) {
        display: none;
    }
}

.space-step-blank {
    width: 70px;
    height: 1px;
}

.register-mobile-edit-label {
    margin-top: 5px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.23px;
    color: $pumpkin;
}

.space-step-on {
    width: 90px;
    height: 1px;
    border: solid 1px #1a3c47;
}

.space-step-off {
    width: 90px;
    height: 1px;
    border: solid 1px #b9b9b9;
}

.text-step {
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.46px;
    color: #1a3c47;
    @include responsive(s) {
        font-size: 1rem;
    }
}

.head-step {
    margin: auto;
    position: relative;
    display: flex;
    align-items: center;
    width: 140px;
    @include responsive(s) {
        position: unset;
        align-items: flex-start;
        width: 17%;
    }
}

.head-step-text {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 7px;
    position: relative;
    display: flex;
    align-items: center;
    width: 152px;
    @include responsive(s) {
        position: unset;
        justify-content: flex-start;
        width: 100%;
    }
    .disabled {
        font-size: 11px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.46px;
        color: #b9b9b9;
    }
}

.progress-step {
    width: 25px;
    height: 25px;
    // content: url('./images/step-progress.svg');
}

.progress-finish {
    width: 25px;
    height: 25px;
    // content: url('./images/progress-finish.svg');
}

.progress-none {
    width: 13px;
    height: 13px;
    // content: url('./images/progress-none.svg');
}

.next-svg {
    width: 17px;
    height: 17px;
    margin-top: auto;
    margin-left: 10px;
    // content: url('./images/next-white.svg');
}

.back-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    // content: url('./images/voltar-gray.svg');
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(226deg) brightness(104%) contrast(101%);
}

.advance-icon {
    width: 16px;
    height: 16px;
    object-fit: contain;
    // content: url('./images/avancar-white.svg');
}

.send-svg {
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 20px;
    // content: url('./images/send.svg');
}

.step-container-mobile {
    display: none;
    @include responsive(s) {
        display: flex;
        margin-right: -7px;
        margin-left: -7px;
        border: solid 1px $ice-blue;
        padding: 18px 22px 18px 22px;
    }
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #5ab0ae !important;
}

.terms {
    font-size: 0.875rem;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #707070;
}

.terms-link {
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #ef7d00;
}

.message-box {
    display: flex;
    width: 488px;
    height: 64px;
    border-radius: 4px;
    background-color: #f5f5f5;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.27px;
    color: #4a4a4a;
    vertical-align: middle;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 12px;
    margin-top: 10px;
    @include responsive(s) {
        width: 100%;
        height: 100%;
        margin-left: 22px;
        margin-right: 22px;
    }
}

.success-back {
    margin-top: 30px;
    position: relative;
    width: 98px;
    height: 98px;
    border-radius: 50%;
    background-color: #fafafa;
}

.success-img {
    margin: 13px;
    position: absolute;
    align-content: center;
    width: 70px;
    height: 70px;
}

.label-radio {
    margin-top: 30px;
    margin-bottom: -10px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #1a3c47;
}

.radio-options {
    display: flex;
    @include responsive(s) {
        align-items: center;
    }
}

.main-text {
    margin-top: 45px;
    margin-bottom: 25px;
    font-size: 40px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #1a3c47;
    @include responsive(s) {
        font-size: 2.5rem;
        font-family: AccordAlt, serif;
        margin-bottom: unset;
    }
}

.main-text-mobile {
    display: none;
    @include responsive(s) {
        display: flex;
        font-size: 1.375rem;
        width: 80%;
        margin: auto;
        padding-top: 30px;
    }
}

.sub-text {
    font-size: 1rem;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: normal;
    text-align: center;
    @include responsive(s) {
        width: 85%;
    }
}

.sub-text-mobile {
    @include responsive(s) {
        font-size: 1.125rem;
        margin-top: 5px;
    }
}

.register-container {
    align-items: center;
    align-content: center;
    text-align: center;
    margin: auto;
    width: 50%;
    @include responsive(s) {
        width: 100vw;
        padding-left: 7px;
        padding-right: 7px;
    }
}

.blank-header {
    min-height: 80px;
    width: 100vw;
    border: solid 1px #e1e2e6;
    background-color: #ffffff;
    @include responsive(s) {
        display: flex;
        align-items: center;
        position: unset;
        justify-content: center;
    }
}

.page-container {
    height: 100vh;
    overflow: auto;
    @include responsive(s) {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
}

.full-height {
    height: 100%;
}

.login-container {
    height: 60vh;
    width: 317px;
    max-width: 100%;
    margin-top: 30vh;
    margin-left: 86px;
    @include responsive(s) {
        display: flex;
        flex-direction: column;
        width: 100vw;
        margin: initial;
        padding-left: 7px;
        padding-right: 7px;
    }
}

.right {
    float: right;
    margin-left: auto;
}

.left {
    float: left;
    margin-right: auto;
}

.container-banner {
    margin: auto;
    width: 410px;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    @include responsive(s) {
        display: flex;
        align-items: center;
        position: unset;
    }
}

.title-banner {
    font-size: 28px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.07;
    letter-spacing: normal;
    color: #ffffff;
    @include responsive(s) {
        display: none;
    }
}

.description-banner {
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #ffffff;
    @include responsive(s) {
        display: none;
    }
}

.link-orange {
    cursor: pointer;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ef7d00 !important;
    display: flex;
    align-items: center;
}

.link-sm {
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.5px;
    color: #707070;
    cursor: pointer;
    text-decoration: underline !important;
}

.title-login-mobile {
    display: none;
    @include responsive(s) {
        display: inline-block;
        text-align: center;
        padding-bottom: 40px;
        width: 90%;
        margin: auto;
        p {
            font-size: 2.5rem;
            font-weight: 500;
            font-family: AccordAlt, serif;
            margin-bottom: -10px;
        }
        span {
            font-size: 1rem;
            line-height: 1.38;
        }
    }
}

.button-container {
    margin-top: 50px;
    @include responsive(s) {
        margin-top: 35px;
        margin-bottom: 40px;
    }
}

.centered-column {
    display: flex !important;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.button-primary {
    border-radius: 25.5px;
    height: 51px;
    border: none;
    width: 118px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
    background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
    margin-left: 20px;
    @include responsive(s) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
        margin-left: 0;
    }
}

.button-primary:hover {
    border-radius: 25.5px;
    height: 51px;
    border: none;
    width: 118px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
    background-image: linear-gradient(to right, #f56f3d, #f1870b 57%, #f59e24);
    opacity: 0.7;
    @include responsive(s) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 150px;
    }
}

.button-second {
    width: 151px;
    height: 51px;
    border-radius: 28px;
    border: solid 1px #a4a4a4;
    background-color: #ffffff;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #a4a4a4;
    margin-right: 20px;
    @include responsive(s) {
        display: none !important;
    }
}

.button-primary:hover {
    border-radius: 25.5px;
    background-color: #ff8400;
    height: 51px;
    border: #ff8400;
    min-width: 115px;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.67px;
    color: #ffffff;
}

.logo {
    position: relative;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 5vh;
    width: 35px;
    height: 45px;
    // content: url('./images/logo@3x.png');
    @include responsive(s) {
        width: 30px;
        height: 38px;
        margin-top: 15px;
        margin-bottom: 15px;
        margin-left: 0;
    }
}

.button-container-login {
    margin-top: 50px;
    @include responsive(s) {
        margin-top: 35px;
        display: flex;
        justify-content: center;
        a {
            display: none;
        }
    }
}

.simple-row {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.simple-col {
    display: flex;
    flex-direction: column;
}

.line-through {
    text-decoration: line-through;
}

.fgs-centered-column {
    display: flex !important;
    height: 100vh;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.base-full-height {
    height: calc(100vh - 108px);
}

.base-access-denied-wrapper {
    display: flex;
    height: 100%;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    @include imageSize(100px, 100px);

    img {
        cursor: pointer;
        margin-bottom: 40px;
    }

    span {
        font-size: 20px;
    }
}

.action-list-icon {
    &:before {
        font-size: 18px;
    }
}

.status-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    @include itemSize(59px, 20px);

    span {
        font-size: 13px;
        font-weight: 500;
    }

    &.is-active {
        color: #00c391;
    }

    &.is-inactive {
        border-radius: 2px;
        background-color: #dedede;
    }
}

.row-list-item {
    display: flex;
    align-items: center;

    div {
        &:first-child {
            margin-right: 18px;
        }
    }
}

.column-list-item {
    display: flex;
    width: 90%;
    flex-direction: column;

    &.is-establishment {
        span {
            &:first-child {
                color: #4a4a4a;
                font-weight: 500;
                word-break: break-word;
            }

            &:last-child {
                color: #7b7b7b;
                max-width: 80%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    &.is-contacts {
        span {
            color: #4a4a4a;
            word-break: break-word;
        }
    }
}

.row-container {
    background-color: white;

    .is-empty-or-loading {
        pointer-events: none;
        height: 274px !important;
        border: 1px solid #D3D9DB;
    }
}

.is-empty-or-loading-wrapper {
    display: flex;
    height: 100%;
    margin-left: -25px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: calc(100% + 25px);

    @include imageSize(64px, 64px);

    img {
        margin-bottom: 20px;
    }

    span {
        color: #6e7475;
        font-size: 22px;
        font-weight: bold;
        text-align: center;
    }
}

.is-item-disabled {
    opacity: 0.5 !important;
    cursor: unset !important;
    pointer-events: none !important;
}
