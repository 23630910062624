@import 'src/utils';
@import 'src/styles/constants/colors';
.select-icn {
    margin-right: 5px;
    @include itemSize(24px, 24px);
}

.check-icn {
    @include itemSize(20px, 20px);
    // content: url('../../images/confirm-icn.png');
}

.radio-click-area {
    width: 50%;
}

.MuiInputBase-input {
    background: white;
}

.MuiFormLabel-root.Mui-focused {
    color: #9199a1 !important;
}

.MuiFilledInput-underline:after {
    border-bottom: none !important;
}

.validate select.MuiSelect-root,
.filled select.MuiSelect-root {
    background: white;
    border-top: 2px solid #00c391;
}

select.MuiSelect-root {
    transition: none !important;
}

.MuiSelect-select:focus.MuiSelect-select:focus {
    background: white;
    transition: none !important;
}

.check-radio-blank {
    width: 27px;
    height: 27px;
    border-radius: 50%;
    border: solid 1px #b9b9b9;
    background-color: #ffffff;
}

.check-radio {
    @include itemSize(27px, 27px);
    // content: url('../../images/radio-checked.svg');
}

.error-label {
    display: flex;
    width: 100%;
    padding-left: 14px;
    span {
        font-size: 12px;
        padding-top: 2px;
        color: $error-two;
        letter-spacing: 0.54px;
    }
}

div.MuiFilledInput-root {
    border-radius: 4px;
    border: 1px solid #d8d8d8;
    background: #f6f6f6 !important;
    overflow: hidden;
}

.MuiFilledInput-underline:before {
    border-bottom: none !important;
}

.input:-internal-autofill-selected {
    background: white;
}