@import 'src/utils';

.convenience-create-or-edit-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .convenience-create-or-edit-wrap {
    display: flex;
    width: 700px;
    overflow: auto;
    max-height: 95vh;
    min-height: 368px;
    position: relative;
    border-radius: 10px;
    flex-direction: column;
    background-color: white;
    padding: 14px 140px 40px;

    &.is-loading {
      position: relative;
      justify-content: flex-start;
      padding-top: calc(368px / 2.1);
    }

    .middle-items {
      display: flex;
      margin-top: 40px;
      margin-bottom: 40px;
      flex-direction: column;

      .pointAmount {
        width: 80% !important;
      }
    }

    .notify-user-wrapper {
      display: flex;
      width: 100%;
      height: 46px;
      padding: 17px;
      margin-top: 20px;
      border-radius: 5px;
      border: solid 1px #fedf58;
      background-color: #fff8d7;
    }

    .while-stock-lasts-wrapper{
      display: flex;
      width: 100%;
      height: 92px;
      padding: 17px;
      margin-top: 20px;
      border-radius: 5px;
      border: solid 1px #fedf58;
      background-color: #fff8d7;
      flex-direction: column;
      font-size: 14px;
      line-height: 1.14;
      letter-spacing: normal;
      color: #9199a1;
    }

    .howManyProducts{
      display: flex;
    }

    .howManyProducts-input{
      width:22% !important;
      border-radius: 5px;
      border: solid 1px #d8d8d8;
      background-color: #f6f6f6;
      margin: 9.5px 8.5px 0 0;
      padding: 7.5px 9.5px;
    }

    .howManyProducts-text{
      padding-top: 7.5px;

    }

    .body-title {
      padding-top: 10px;
      color: #4a4a4a;
      font-size: 22px;
      font-weight: 500;
      text-align: center;
    }

    .icon-fechar:before {
      right: 25px;
      color: #143d66;
      font-size: 32px;
      cursor: pointer;
      position: absolute;
    }
  }
}

.saleTitle { 
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin-top: 20px;
  margin-bottom: 10px;
}

.line {
  width: 100%;
  height: 1px;
  margin: 20px 115px 0px 0px;
  border: solid 1px #fed100;
}