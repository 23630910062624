@font-face {
    font-family: 'icomoon';
    src: url('fonts/icomoon.eot?cf5hqd');
    src: url('fonts/icomoon.eot?cf5hqd#iefix') format('embedded-opentype'), url('fonts/icomoon.ttf?cf5hqd') format('truetype'), url('fonts/icomoon.woff?cf5hqd') format('woff'), url('fonts/icomoon.svg?cf5hqd#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^='icon-'],
[class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-voltar:before {
    content: '\e900';
    color: #34bfff;
}

.icon-icone-talher:before {
    content: '\e901';
}

.icon-erro .path1:before {
    content: '\e902';
    color: rgb(254, 209, 0);
}

.icon-erro .path2:before {
    content: '\e903';
    margin-left: -1em;
    color: rgb(20, 61, 102);
}

.icon-erro-conexao .path1:before {
    content: '\e904';
    color: rgb(254, 209, 0);
}

.icon-erro-conexao .path2:before {
    content: '\e905';
    margin-left: -1em;
    color: rgb(20, 61, 102);
}

.icon-sucesso .path1:before {
    content: '\e906';
    color: rgb(254, 209, 0);
}

.icon-sucesso .path2:before {
    content: '\e907';
    margin-left: -1em;
    color: rgb(18, 60, 103);
}

.icon-lupa:before {
    content: '\e908';
    color: #bababa;
}

.icon-imagem_rounded:before {
    content: '\e909';
    color: #bababa;
}

.icon-editar:before {
    content: '\e90a';
    color: #bababa;
}

.icon-editar:hover:before {
    color: #1e7ac9;
    transition: 250ms;
}

.icon-editar:active:before {
    color: #143d66;
    transition: 250ms;
}

.icon-deletar-reverter:before {
    content: '\e90b';
    color: #bababa;
}

.icon-deletar-reverter:hover:before {
    color: #1e7ac9;
    transition: 250ms;
}

.icon-deletar-reverter:active:before {
    color: #143d66;
    transition: 250ms;
}

.icon-deletar:before {
    content: '\e90c';
    color: #bababa;
}

.icon-deletar:hover:before {
    color: #1e7ac9;
    transition: 250ms;
}

.icon-deletar:active:before {
    color: #143d66;
    transition: 250ms;
}

.icon-sucesso_rounded:before {
    content: '\e90d';
    color: #bababa;
}

.icon-fechar:before {
    content: '\e90e';
    color: #bababa;
}

.icon-flechas:before {
    content: '\e90f';
    color: #bababa;
}

.icon-dropdown:before {
    content: '\e910';
    color: #bababa;
}

.icon-email:before {
    content: '\e911';
    color: #bababa;
}

.icon-tel:before {
    content: '\e912';
    color: #bababa;
}

.icon-perfil:before {
    content: '\e913';
    color: #bababa;
}

.icon-pin:before {
    content: '\e914';
    color: #bababa;
}

.icon-restaurante_rounded:before {
    content: '\e915';
    color: #bababa;
}

.icon-seta:before {
    content: '\e916';
    color: #bababa;
}

.icon-delete_rounded:before {
    content: '\e917';
    color: #bababa;
}

.icon-delete_rounded:hover:before {
    color: #1e7ac9;
    transition: 250ms;
}

.icon-delete_rounded:active:before {
    color: #143d66;
    transition: 250ms;
}