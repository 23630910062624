@import '../../utils';
@import '../../styles/constants/colors';
@import '../../styles/constants/fonts';

.custom-check-box-wrapper {
  display: flex;
  width: 100%;
  align-items: center;

  .custom-check-box-wrap {
    display: flex;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 4px;
    align-items: center;
    background-color: white;
    justify-content: center;
    border: solid 1.3px #898D8F;
    user-select: none !important;

    @include imageSize(22px, 22px);
  }

  span {
    color: #4a4a4a;
    font-size: 13px;
    margin-left: 8px;
  }
}

.checked-text-color {
  color: $greyish-brown !important;
}
