@import 'src/utils';
.image-upload-wrapper {
    display: flex;
    position: relative;
    border-radius: 8px;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #fafafa;
    border: dashed 2px #cacaca;
    margin-bottom: 20px;
    .delete-image {
        top: 10px;
        right: 10px;
        cursor: pointer;
        position: absolute;
        border-radius: 999px;
        @include itemSize(26px, 26px);
    }
    .image-rendering {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        img {
            border-radius: 8px;
        }
        @include imageSize(99%, 99%);
    }
    .icon-camera {
        font-size: 34px;
        margin-bottom: 8px;
    }
    .image-upload-title-wrapper {
        display: flex;
        width: 123px;
        margin-bottom: 10px;
        flex-direction: column;
        span {
            color: #9199a1;
            font-size: 16px;
            font-weight: 300;
            line-height: 1.5;
            text-align: center;
            letter-spacing: 0.67px;
        }
        .item-size {
            margin-top: 2px;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0.58px;
        }
    }
    .image-upload-button {
        display: flex;
        width: 196px;
        height: 27px;
        cursor: pointer;
        align-items: center;
        border-radius: 5.7px;
        justify-content: center;
        background-color: #1e7ac9;
        input[type='file'] {
            opacity: 0;
            position: absolute;
            pointer-events: none;
            @include itemSize(1px, 1px);
        }
        span {
            color: #ffffff;
            line-height: 1.5;
            font-size: 11.3px;
            font-weight: bold;
            text-align: center;
        }
    }
}

.text{
    width: 155px;
    height: 13px;
    margin: 10px 20px 0 20px;
    font-family: Roboto;
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    display: flex;
    justify-content: space-around;
}


.image-text{
    color: #7b7b7b;
}

.image-size {
    color: #4a4a4a;
}