@import 'src/utils';

.toast-body-element-wrapper {
  display: flex;
  width: 100%;
  padding-left: 10px;
  align-items: center;

  @include imageSize(26px, 26px);

  img {
    margin-right: 25px;
  }
}
