@import 'src/utils';

.fork-knife-icon-wrapper {
  display: flex;
  align-items: center;
  border-radius: 999px;
  justify-content: center;
  border: solid 0.9px #fdd00f;

  @include itemSize(34px, 34px);
  @include imageSize(19px, 19px);

  &.is-disabled {
    border: solid 0.9px #9199a1;
  }
}
