@import './icon-font.scss';
@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Thin.ttf);
    font-weight: 100;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url(./fonts/Roboto-ThinItalic.ttf);
    font-weight: 100;
}

@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Thin.ttf);
    font-weight: 200;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url(./fonts/Roboto-ThinItalic.ttf);
    font-weight: 200;
}

@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Light.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url(./fonts/Roboto-LightItalic.ttf);
    font-weight: 300;
}

@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Regular.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url(./fonts/Roboto-Italic.ttf);
    font-weight: 400;
}

@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Medium.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url(./fonts/Roboto-MediumItalic.ttf);
    font-weight: 500;
}

@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Medium.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url(./fonts/Roboto-MediumItalic.ttf);
    font-weight: 600;
}

@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Bold.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url(./fonts/Roboto-BoldItalic.ttf);
    font-weight: 700;
}

@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Bold.ttf);
    font-weight: 800;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url(./fonts/Roboto-BoldItalic.ttf);
    font-weight: 800;
}

@font-face {
    font-family: 'Roboto';
    src: url(./fonts/Roboto-Black.ttf);
    font-weight: 900;
}

@font-face {
    font-family: 'RobotoItalic';
    src: url(./fonts/Roboto-BlackItalic.ttf);
    font-weight: 900;
}